import axios from 'axios';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { notify } from '../Toast';
import styles from './style.module.scss';
import { dateFormat } from '../../utils/dateFormat';
import { CurrencyFormatter } from '../../utils/currencyFormatter';

interface ModalProps {
    userId: string;
    purchasedPolicyId: string;
    updatePoliciesTables: () => void;
    oldStartDate: EpochTimeStamp;
    setEditable: () => void;
    formSource: string;
}

const UpdatePolicyModalBox = ({
    purchasedPolicyId,
    userId,
    updatePoliciesTables,
    oldStartDate,
    setEditable,
    formSource,
}: ModalProps) => {
    const [show, setShow] = useState(true);
    const handleClose = () => {
        setEditable();
        setShow(false);
    };

    const [policyHolder, setpolicyHolder] = useState('');
    const [policyNumber, setPolicyNumber] = useState('');
    const [proposalNumber, setProposalNumber] = useState('');
    const [startDate, setStartDate] = useState('');
    //   const [basicPremium, setBasicPremium] = useState("");
    const [file, setFile] = useState<File | null>();

    const [isStartDateDifferent, setIsStartDateDifferent] = useState(false);
    //   const [isBasicPremiumDifferent, setIsBasicPremiumDifferent] = useState(false);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Check if files are present
        if (e.target.files && e.target.files[0]) {
            setFile(e.target.files[0]);
        } else {
            notify.error('No file selected');
            setFile(null); // Set fileData to null to handle the case where the user unselects the file
        }
    };

    const handleCheckboxChange = () => {
        setIsStartDateDifferent(!isStartDateDifferent);
    };

    const [insuredPoints, setinsuredPoints] = useState<{
        [key: string]: string;
    }>({});
    const [insuredPointskeys, setinsuredPointskeys] = useState<string[]>([]);

    const handleAddInsueredField = () => {
        const newKey = `${insuredPointskeys.length + 1}`;
        setinsuredPointskeys([...insuredPointskeys, newKey]);
    };

    const handleInsured = (
        e: React.ChangeEvent<HTMLInputElement>,
        key: string,
    ) => {
        const { value } = e.target;

        setinsuredPoints((prevData) => ({ ...prevData, [key]: value }));
    };

    const [uninsuredPoints, setuinsuredPoints] = useState<{
        [key: string]: string;
    }>({});
    const [uinsuredPointskeys, setUinsuredPointskeys] = useState<string[]>([]);

    const handleAddUninsuredField = () => {
        const newKey = `${uinsuredPointskeys.length + 1}`;
        setUinsuredPointskeys([...uinsuredPointskeys, newKey]);
    };

    const handleUninsured = (
        e: React.ChangeEvent<HTMLInputElement>,
        key: string,
    ) => {
        const { value } = e.target;
        setuinsuredPoints((prevData) => ({ ...prevData, [key]: value }));
    };

    const updatePolicy = async () => {
        const formData = new FormData();
        formData.append('id', purchasedPolicyId);
        formData.append('status', 'active');
        if (startDate && isStartDateDifferent)
            formData.append('policyStartDate', startDate);
        // if (basicPremium)
        //   formData.append(
        //     "basicPremium",
        //     CurrencyFormatter(basicPremium).toString()
        //   );
        // formData.append(
        //   "taxOnPremium",
        //   JSON.stringify((parseInt(basicPremium) * 18) / 100)
        // );
        // formData.append(
        //   "grossPremium",
        //   JSON.stringify(
        //     (parseInt(basicPremium) * 18) / 100 + parseInt(basicPremium)
        //   )
        // );
        if (proposalNumber) formData.append('proposalNumber', proposalNumber);
        if (file) {
            formData.append('policyPDF', file);
        }
        if (Object.keys(insuredPoints).length > 0)
            formData.append('insuredPoints', JSON.stringify(insuredPoints));
        if (Object.keys(uninsuredPoints).length > 0)
            formData.append('uninsuredPoints', JSON.stringify(uninsuredPoints));
        if (!policyHolder && formSource === 'pending') {
            notify.error('Policy Holder is required');
            return;
        } else if (policyHolder) {
            formData.append('policyHolder', policyHolder);
        }
        if (!policyNumber && formSource === 'pending') {
            notify.error('Policy Number is required');
            return;
        } else if (policyNumber) {
            formData.append('policyNumber', policyNumber);
        }
        try {
            const authData = JSON.parse(
                localStorage.getItem('authCheck') ?? 'null',
            );
            const headers = {
                Authorization: `Bearer ${authData.adminAuth}`,
            };
            await axios.patch(
                `${process.env.REACT_APP_BASE_URL}/v1/policies/users/${userId}`,
                formData,
                { headers },
            );
            notify.success('Policy Status Updated to Active');
            updatePoliciesTables();
            handleClose();
        } catch (error) {
            notify.error('Error Updating Policy');
        }
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Policy - Policy ID {purchasedPolicyId}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label
                                className={
                                    formSource === 'pending'
                                        ? styles.required
                                        : ''
                                }
                            >
                                Policy Holder Name
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={policyHolder}
                                onChange={(e) =>
                                    setpolicyHolder(e.target.value)
                                }
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label
                                className={
                                    formSource === 'pending'
                                        ? styles.required
                                        : ''
                                }
                            >
                                Policy Number
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={policyNumber}
                                onChange={(e) =>
                                    setPolicyNumber(e.target.value)
                                }
                                autoFocus
                            />
                            <Form.Label>Proposal Number</Form.Label>
                            <Form.Control
                                type="text"
                                value={proposalNumber}
                                onChange={(e) =>
                                    setProposalNumber(e.target.value)
                                }
                                autoFocus
                            />
                        </Form.Group>

                        <Form.Label className="mb-3">
                            <input
                                className={styles.checkBox}
                                type="checkbox"
                                checked={isStartDateDifferent}
                                onChange={handleCheckboxChange}
                            />
                            User entered {dateFormat(oldStartDate)} as start
                            date. Do you want to change it?
                        </Form.Label>
                        {isStartDateDifferent ? (
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={startDate}
                                    onChange={(e) =>
                                        setStartDate(e.target.value)
                                    }
                                    autoFocus
                                />
                            </Form.Group>
                        ) : (
                            <></>
                        )}

                        {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Basic Premium(without GST)</Form.Label>
              <Form.Control
                type="text"
                value={basicPremium}
                onChange={(e) => setBasicPremium(e.target.value)}
                autoFocus
              />
            </Form.Group> */}
                        <div className={styles.points}>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                {insuredPointskeys.map((key) => (
                                    <Form.Label key={key}>
                                        {key}:
                                        <input
                                            type="text"
                                            name={key}
                                            value={insuredPoints[key] || ''}
                                            onChange={(e) =>
                                                handleInsured(e, key)
                                            }
                                        />
                                    </Form.Label>
                                ))}
                            </Form.Group>
                        </div>
                        <Button
                            type="button"
                            className="mt-2"
                            onClick={handleAddInsueredField}
                        >
                            Add Insured Points
                        </Button>

                        <br></br>

                        <div className={styles.points}>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                {uinsuredPointskeys.map((key) => (
                                    <Form.Label key={key}>
                                        {key}:
                                        <input
                                            type="text"
                                            name={key}
                                            value={uninsuredPoints[key] || ''}
                                            onChange={(e) =>
                                                handleUninsured(e, key)
                                            }
                                        />
                                    </Form.Label>
                                ))}
                            </Form.Group>
                        </div>
                        <Button
                            type="button"
                            onClick={handleAddUninsuredField}
                            className="mt-2"
                        >
                            Add UnInsured Points
                        </Button>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label>Policy PDF</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={handleFileChange}
                                autoFocus
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={updatePolicy}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default UpdatePolicyModalBox;
