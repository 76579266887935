import React, { useState } from 'react';
import axios from 'axios';
import styles from './AdminLogin.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import { notify } from '../../common/Toast';
import { UserRole } from '../../types/enums';
interface LoginPageProps {
    onLogin: () => void;
}

const AdminLogin: React.FC<LoginPageProps> = ({ onLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v1/auth/login`,
                {
                    email,
                    password,
                },
            );

            const { data } = response;
            if (
                data.data.role === UserRole.Admin ||
                data.data.role === UserRole.Partner
            ) {
                notify.success('Logged In!');
                let result = {
                    adminAuth: data.data.token,
                    expireTime: Date.now() + 24 * 60 * 60 * 1000, // T + 1 Day
                    role: data.data.role,
                };
                localStorage.setItem('authCheck', JSON.stringify(result));
                onLogin();
            } else {
                notify.error('Unauthorized!');
            }
        } catch (error) {
            notify.error(`${error}`);
        }
    };

    return (
        <div className={styles.app}>
            <form className={styles.login_form} onSubmit={handleSubmit}>
                <h1>Login</h1>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                />

                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                />

                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default AdminLogin;
