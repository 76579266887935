// Notification.tsx
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const notify = {
    success: (message: string, autoClose: number = 5000) => {
        toast.success(message, { autoClose, theme: 'colored' });
    },
    error: (message: string, autoClose: number = 5000) => {
        toast.error(message, { autoClose, theme: 'colored' });
    },
};
