export const dateFormat = (value: EpochTimeStamp) => {
    const timestampStr = value;
    const timestampObj = new Date(timestampStr);

    // Format the date
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };
    const formattedDate = timestampObj.toLocaleDateString('en-US', options);

    return formattedDate;
};
