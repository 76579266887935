import { Table, Tab, Tabs } from 'react-bootstrap';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { notify } from '../../common/Toast';
import styles from './GetQuotes.module.scss';
import { dateFormat } from '../../utils/dateFormat';
// import CallBackRequestStatusUpdate from '../../common/CallBackRequestStatusUpdate';
// import editIcon from '../../assets/images/editIcon.svg';

interface Row {
    id: string;
    name: string;
    phone: string;
    company_name: string;
    industry: string;
    source: string;
    status: string;
    isExistingUser: boolean;
    createdAt: EpochTimeStamp;
}

const GetQuotes = () => {
    const [GetQuotesRequests, setGetQuotesRequests] = useState([]);
    // const [
    //     editGetQuotesRequestsStatusOption,
    //     setEditGetQuotesRequestsStatusOption,
    // ] = useState(-1);
    // const [selectedOption, setSelectedOption] = useState('pending');

    const fetchGetQuotesRequestss = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/get-quote/list`,
            );
            setGetQuotesRequests(response.data.data);
        } catch (error) {
            notify.error(`${error}`);
        }
    };
    // const handleTickClick = async (GetQuotesRequestsId: string) => {
    //     try {
    //         const authData = JSON.parse(
    //             localStorage.getItem('authCheck') ?? 'null',
    //         );
    //         const headers = {
    //             'Content-Type': 'application/json',
    //             Authorization: `Bearer ${authData.adminAuth}`,
    //         };
    //         const response = await axios.patch(
    //             `${process.env.REACT_APP_BASE_URL}/v1/admins/users/callback-requests/${GetQuotesRequestsId}`,
    //             {
    //                 status: selectedOption,
    //             },
    //             {
    //                 headers,
    //             },
    //         );
    //         const { data } = response;
    //         if (data.success) {
    //             fetchGetQuotesRequestss();
    //             handleCrossClick();
    //             notify.success(
    //                 `Status Updated for Call Back Request Id: ${GetQuotesRequestsId}`,
    //             );
    //         }
    //     } catch (error) {
    //         notify.error('Error Updating Status!');
    //     }
    // };

    // const handleCrossClick = () => {
    //     setEditGetQuotesRequestsStatusOption(-1);
    //     setSelectedOption('');
    // };

    useEffect(() => {
        fetchGetQuotesRequestss();
    }, []);

    return (
        <div className={styles.tab}>
            <Tabs defaultActiveKey="GetQuotesRequests" id="fill-tab-example">
                <Tab eventKey="GetQuotesRequests" title="Get Quotes Requests">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Company Name</th>
                                <th>Industry</th>
                                <th>Source</th>
                                <th>Is Existing User</th>
                                <th>Status</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {GetQuotesRequests?.map(
                                (row: Row, index: number) => (
                                    <tr key={row.id}>
                                        <td>{row.name}</td>
                                        <td>{row.phone}</td>
                                        <td>{row.company_name}</td>
                                        <td>{row.industry}</td>
                                        <td>{row.source}</td>
                                        <td>
                                            {row.isExistingUser ? 'Yes' : 'No'}
                                        </td>
                                        <td>
                                            {/*
                                        {editGetQuotesRequestsStatusOption ===
                                            index ? (
                                                <div
                                                    className={
                                                        styles.quoteUpdate
                                                    }
                                                >
                                                    <CallBackRequestStatusUpdate
                                                        selectedOption={
                                                            row.status
                                                        }
                                                        setSelectedOption={
                                                            setSelectedOption
                                                        }
                                                    />
                                                    <div
                                                        onClick={() =>
                                                            handleTickClick(
                                                                row.id,
                                                            )
                                                        }
                                                    >
                                                        ✅
                                                    </div>
                                                    <div
                                                        onClick={
                                                            handleCrossClick
                                                        }
                                                    >
                                                        ❌
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    {row.status}
                                                    <img
                                                        onClick={() =>
                                                            setEditGetQuotesRequestsStatusOption(
                                                                index,
                                                            )
                                                        }
                                                        className={
                                                            styles.editIcon
                                                        }
                                                        src={editIcon}
                                                        alt=""
                                                    />
                                                </>
                                            )}
                                            */}
                                            {row.status}
                                        </td>
                                        <td>{dateFormat(row.createdAt)}</td>
                                    </tr>
                                ),
                            )}
                        </tbody>
                    </Table>
                </Tab>
            </Tabs>
        </div>
    );
};

export default GetQuotes;
