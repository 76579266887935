import { useState } from 'react';
interface CallBackRequestStatusProps {
    selectedOption: string;
    setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
}
const CallBackRequestStatusUpdate: React.FC<CallBackRequestStatusProps> = ({
    selectedOption,
    setSelectedOption,
}) => {
    const status = ['pending', 'fulfilled'];

    const [value, setValue] = useState(selectedOption);

    const handleStatusChange = (
        event: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        setSelectedOption(event.target.value);
        setValue(event.target.value);
    };
    return (
        <div>
            <select
                name="statusType"
                value={value}
                onChange={handleStatusChange}
            >
                {status.map((option, index) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    );
};
export default CallBackRequestStatusUpdate;
