import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { notify } from '../../common/Toast';
import styles from './CallBackRequest.module.scss';
import { dateFormat } from '../../utils/dateFormat';
import CallBackRequestStatusUpdate from '../../common/CallBackRequestStatusUpdate';
import editIcon from '../../assets/images/editIcon.svg';

interface Row {
    id: string;
    companyName: string;
    name: string;
    phone: string;
    createdAt: EpochTimeStamp;
    status: string;
}

const CallBackRequests = () => {
    const [CallBackRequests, setCallBackRequests] = useState([]);
    const [
        editCallBackRequestStatusOption,
        setEditCallBackRequestStatusOption,
    ] = useState(-1);
    const [selectedOption, setSelectedOption] = useState('pending');

    const fetchCallBackRequests = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v1/admins/users/callBack-Requests`,
            );
            setCallBackRequests(response.data.data);
        } catch (error) {
            notify.error(`${error}`);
        }
    };
    const handleTickClick = async (callBackRequestId: string) => {
        try {
            const authData = JSON.parse(
                localStorage.getItem('authCheck') ?? 'null',
            );
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authData.adminAuth}`,
            };
            const response = await axios.patch(
                `${process.env.REACT_APP_BASE_URL}/v1/admins/users/callback-requests/${callBackRequestId}`,
                {
                    status: selectedOption,
                },
                {
                    headers,
                },
            );
            const { data } = response;
            if (data.success) {
                fetchCallBackRequests();
                handleCrossClick();
                notify.success(
                    `Status Updated for Call Back Request Id: ${callBackRequestId}`,
                );
            }
        } catch (error) {
            notify.error('Error Updating Status!');
        }
    };

    const handleCrossClick = () => {
        setEditCallBackRequestStatusOption(-1);
        setSelectedOption('');
    };

    useEffect(() => {
        fetchCallBackRequests();
    }, []);
    return (
        <div className={styles.tab}>
            <Tabs defaultActiveKey="CallBackrequests" id="fill-tab-example">
                <Tab eventKey="CallBackrequests" title="Call Back Requests">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Company Name</th>
                                <th>User Name</th>
                                <th>Phone no.</th>
                                <th>Status</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {CallBackRequests?.map(
                                (row: Row, index: number) => (
                                    <tr key={row.id}>
                                        <td>{row.companyName}</td>
                                        <td>{row.name}</td>
                                        <td>{row.phone}</td>
                                        <td>
                                            {editCallBackRequestStatusOption ===
                                            index ? (
                                                <div
                                                    className={
                                                        styles.quoteUpdate
                                                    }
                                                >
                                                    <CallBackRequestStatusUpdate
                                                        selectedOption={
                                                            row.status
                                                        }
                                                        setSelectedOption={
                                                            setSelectedOption
                                                        }
                                                    />
                                                    <div
                                                        onClick={() =>
                                                            handleTickClick(
                                                                row.id,
                                                            )
                                                        }
                                                    >
                                                        ✅
                                                    </div>
                                                    <div
                                                        onClick={
                                                            handleCrossClick
                                                        }
                                                    >
                                                        ❌
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    {row.status}
                                                    <img
                                                        onClick={() =>
                                                            setEditCallBackRequestStatusOption(
                                                                index,
                                                            )
                                                        }
                                                        className={
                                                            styles.editIcon
                                                        }
                                                        src={editIcon}
                                                    />
                                                </>
                                            )}
                                        </td>
                                        <td>{dateFormat(row.createdAt)}</td>
                                    </tr>
                                ),
                            )}
                        </tbody>
                    </Table>
                </Tab>
            </Tabs>
        </div>
    );
};

export default CallBackRequests;
